// node modules
import React, { lazy, Suspense } from 'react'
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import LazyLoad from 'react-lazyload'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// sections
import Hero from 'components/pages/homepage/hero'
import Breadcrumbs from 'components/shared/breadcrumbs'

import slugify from 'utils/slugify'

import media from 'styles/media'

// types
import type { HeroSlide } from 'types/homepage'
import type { Point } from 'types/point'
import type { AccessoryProps } from 'types/accessories'

const Accessories = lazy(() => import('components/pages/accessories'))
const FindDealer = lazy(() => import('components/shared/find-dealer'))

const PageBreadcrumbs = styled.div`
  ${media.md.min} {
    margin: -50px 0 0 0;
  }
`

const PageFindDealer = styled.div`
  margin: 0 0 100px 0;

  ${media.md.min} {
    margin: 0 0 128px 0;
  }
`

const AccessoriesPage: React.FC<PageProps<Queries.AccessoriesPageQuery>> = ({
  data,
}) => {
  const PAGE = data.AccessoriesPage?.PageAccessories!
  const PAGE_SEO = data.AccessoriesPage?.seo!
  const PAGE_HOME = data.HomePage?.onepage!

  if (!PAGE || !PAGE_SEO || !PAGE_HOME)
    throw new Error("CMS data didn't load properly")

  const HERO_SLIDES: HeroSlide[] =
    PAGE?.pageAccessoriesHero
      ?.filter((slide) => slide?.pageAccessoriesHeroImg)
      ?.map((slide) => ({
        image: {
          src: slide?.pageAccessoriesHeroImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: slide?.pageAccessoriesHeroImg?.altText!,
        },
        objPosX: slide?.pageAccessoriesHeroImgObjectPositionX!,
        heading: slide?.pageAccessoriesHeroTitle!,
        buttonText: slide?.pageAccessoriesHeroButton!,
        buttonLink: slide?.pageAccessoriesHeroUrl!,
      })) || []

  const ACCESSORIES_LIST: AccessoryProps[] =
    data?.Accessories?.edges?.map(({ node }) => {
      const accessory = node?.Accessory

      return {
        title: node?.title!,
        slug: slugify(node?.title!),
        photo:
          accessory?.infoPhoto?.localFile?.childImageSharp?.gatsbyImageData!,
        variants: accessory?.infoVariants?.map((variant) => ({
          name: variant?.infoVariantsName!,
          photo:
            variant?.infoVariantsImg?.localFile?.childImageSharp
              ?.gatsbyImageData!,
        })),
        sku: accessory?.infoSku!,
        price: accessory?.infoPrice!,
        priceStatus: accessory?.infoPriceStatus!,
        description: accessory?.featuresList?.map((row) => ({
          text: row!.featuresListText!,
        })),
      }
    }) || []

  const SERVICE_POINTS: Point[] =
    PAGE_HOME?.dealer
      ?.filter((dealer) => dealer?.dealerName && dealer?.dealerContact)
      ?.map((dealer) => ({
        name: dealer?.dealerName!,
        address: dealer?.dealerContact!,
        openingHours: dealer?.dealerHours?.map((item) => ({
          days: item?.dealerHoursDay!,
          hours: item?.dealerHoursHours!,
        }))!,
        phoneNumber: dealer?.dealerPhoneNumber!,
        routeLink: dealer?.dealerUrl!,
        latitude: Number(dealer?.dealerLat)!,
        longitude: Number(dealer?.dealerLng)!,
        isShowroom: Boolean(dealer?.dealerSalesOffice),
        isServicePoint: Boolean(dealer?.dealerService),
        id: uuidv4(),
        postCode: dealer?.dealerPostCode!,
        city: dealer?.dealerCity!,
      })) || []

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Hero slides={HERO_SLIDES} />
      <PageBreadcrumbs>
        <Breadcrumbs crumbs={[{ name: data.AccessoriesPage?.title! }]} />
      </PageBreadcrumbs>
      <LazyLoad once offset={225}>
        <Suspense fallback={<></>}>
          <Accessories
            heading={PAGE.pageAccessoriesListHeading || 'Akcesoria'}
            accessories={ACCESSORIES_LIST}
          />
        </Suspense>
      </LazyLoad>
      <LazyLoad once offset={225}>
        <Suspense fallback={<></>}>
          <PageFindDealer>
            <FindDealer points={SERVICE_POINTS} page="homepage" />
          </PageFindDealer>
        </Suspense>
      </LazyLoad>
    </Layout>
  )
}

export default AccessoriesPage

export const query = graphql`
  query AccessoriesPage {
    AccessoriesPage: wpPage(slug: { regex: "/akcesoria/" }) {
      title
      PageAccessories {
        pageAccessoriesListHeading
        pageAccessoriesHero {
          pageAccessoriesHeroUrl
          pageAccessoriesHeroButton
          pageAccessoriesHeroTitle
          pageAccessoriesHeroImgObjectPositionX
          pageAccessoriesHeroImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: DOMINANT_COLOR
                  breakpoints: [1280, 1920]
                )
              }
            }
          }
        }
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
      seo {
        ...WpSEO
      }
    }
    Accessories: allWpAccessory {
      edges {
        node {
          title
          Accessory {
            infoSku
            infoPrice
            infoPriceStatus
            infoPhoto {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 900, placeholder: DOMINANT_COLOR)
                }
              }
            }
            infoVariants {
              infoVariantsName
              infoVariantsImg {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 900, placeholder: DOMINANT_COLOR)
                  }
                }
              }
            }
            featuresList {
              featuresListText
            }
          }
        }
      }
    }
    HomePage: wpPage(slug: { regex: "/onepage/" }) {
      onepage {
        dealer {
          dealerName
          dealerContact
          dealerCity
          dealerPostCode
          dealerHours {
            dealerHoursDay
            dealerHoursHours
          }
          dealerPhoneNumber
          dealerLat
          dealerLng
          dealerUrl
          dealerService
          dealerSalesOffice
          dealerMobileService
        }
      }
    }
  }
`
